// extracted by mini-css-extract-plugin
export var footer = "PageLayout-module--footer--6JxB7";
export var header = "PageLayout-module--header--xZM3d";
export var headerInfo = "PageLayout-module--headerInfo--JJX1k";
export var headerTitle = "PageLayout-module--headerTitle--WfMr2";
export var headerOverlay = "PageLayout-module--headerOverlay--mymjR";
export var playPauseWrapper = "PageLayout-module--playPauseWrapper--MUCRH";
export var mainContent = "PageLayout-module--mainContent--FSHCh";
export var sectionBtnExclusive = "PageLayout-module--sectionBtnExclusive--DZAd1";
export var btnExclusiveContent = "PageLayout-module--btnExclusiveContent--KvW8A";
export var btnIcon = "PageLayout-module--btnIcon--InbuG";
export var twitter = "PageLayout-module--twitter--r3Ew2";
export var sectionGallery = "PageLayout-module--sectionGallery--PNbyk";
export var sectionForm = "PageLayout-module--sectionForm--pvVyh";
export var bottomLogo = "PageLayout-module--bottomLogo--X5hY+";
export var privacyPolicy = "PageLayout-module--privacyPolicy---L6XA";