import {
  IconDiscord,
  IconFansly,
  IconInstagram,
  IconKick,
  IconLock,
  IconReddit,
  IconSnapchat,
  IconStar,
  IconTiktok,
  IconTwitch,
  IconTwitter,
  IconYoutube,
  IconOFTV,
} from '../assets/SvgSprite';

const DEFAULT_ICON_ORDER = [
  {
    icon: 'Instagram',
    text: 'Instagram',
    order: 1.1,
  },
  {
    icon: 'Exclusive_Content',
    text: 'Exclusive content',
    order: 2.1,
  },
  {
    icon: 'Tiktok',
    text: 'Tiktok',
    order: 3.1,
  },
  {
    icon: 'Twitter',
    text: 'Twitter',
    order: 4.1,
  },
  {
    icon: 'Youtube',
    text: 'Youtube',
    order: 5.1,
  },
  {
    icon: 'Twitch',
    text: 'Twitch',
    order: 6.1,
  },
  {
    icon: 'Fansly',
    text: 'Fansly',
    order: 7.1,
  },
  {
    icon: 'Reddit',
    text: 'Reddit',
    order: 8.1,
  },
  {
    icon: 'Discord',
    text: 'Discord',
    order: 9.1,
  },
  {
    icon: 'Kick',
    text: 'Kick',
    order: 10.1,
  },
  {
    icon: 'Snapchat',
    text: 'Snapchat',
    order: 11.1,
  },
  {
    icon: 'OFTV',
    text: 'OFTV',
    order: 12.1,
  },
];

const IconComponents = {
  'Youtube': IconYoutube,
  'Twitter': IconTwitter,
  'Twitch': IconTwitch,
  'Tiktok': IconTiktok,
  'Snapchat': IconSnapchat,
  'Reddit': IconReddit,
  'Exclusive_Content': IconLock,
  'Kick': IconKick,
  'Instagram': IconInstagram,
  'Fansly': IconFansly,
  'Discord': IconDiscord,
  'Star': IconStar,
  'OFTV': IconOFTV,
};

export {
  DEFAULT_ICON_ORDER,
  IconComponents,
};
