import React from 'react';

export const IconFansly = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="-21 0 160 100" width="24"
		     height="15">
			<g>
				<path fill="#51433C"
				      d="M 26.5,-0.5 C 29.8333,-0.5 33.1667,-0.5 36.5,-0.5C 46.0658,2.37257 53.7325,8.03923 59.5,16.5C 56.4684,20.3659 52.9684,23.6992 49,26.5C 45.5112,23.6804 42.0112,20.847 38.5,18C 23.9012,13.5859 17.4012,19.0859 19,34.5C 22.648,39.315 26.8147,43.6484 31.5,47.5C 45.9861,33.5141 60.3194,19.3474 74.5,5C 78.4506,2.85477 82.4506,1.02144 86.5,-0.5C 89.8333,-0.5 93.1667,-0.5 96.5,-0.5C 101.955,0.893892 106.955,3.39389 111.5,7C 115.415,11.3435 118.415,16.1768 120.5,21.5C 120.5,26.5 120.5,31.5 120.5,36.5C 117.382,45.0159 112.048,52.0159 104.5,57.5C 100.5,54.1667 96.8333,50.5 93.5,46.5C 97.1863,42.6481 100.686,38.6481 104,34.5C 105.838,20.002 99.6717,14.1687 85.5,17C 80.8663,20.6321 76.533,24.6321 72.5,29C 82.681,39.0141 92.681,49.1808 102.5,59.5C 89.7853,72.8814 76.7853,85.8814 63.5,98.5C 62.1667,98.5 60.8333,98.5 59.5,98.5C 40.502,80.6683 22.3353,62.0016 5,42.5C -0.335437,29.3503 1.83123,17.517 11.5,7C 16.0454,3.39389 21.0454,0.893892 26.5,-0.5 Z M 56.5,46.5 C 69.1565,45.3244 74.9899,50.9911 74,63.5C 71.0341,71.055 65.5341,73.8883 57.5,72C 49.724,68.7122 46.8907,62.8789 49,54.5C 51.0282,51.3078 53.5282,48.6411 56.5,46.5 Z"/>
			</g>
			<g>
				<path fill="#51433C"
				      d="M 58.5,49.5 C 60.5,49.5 62.5,49.5 64.5,49.5C 64.2148,51.288 64.5481,52.9547 65.5,54.5C 67.1173,54.6694 68.784,55.0028 70.5,55.5C 72.4341,63.2475 69.4341,67.9142 61.5,69.5C 51.9014,66.9693 49.4014,61.3026 54,52.5C 55.6516,51.6006 57.1516,50.6006 58.5,49.5 Z"/>
			</g>
		</svg>
	);
};
