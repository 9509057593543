import React from 'react';

export const IconPlay = () => {
	return (
		<svg
			width="12"
			height="17"
			viewBox="0 0 12 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.05008 16.4624L11.5847 9.31655C12.1359 8.90822 12.1359 8.09155 11.5847 7.68322L2.05008 0.537386C1.37633 0.0269691 0.416748 0.516968 0.416748 1.35405V15.6457C0.416748 16.4828 1.37633 16.9728 2.05008 16.4624Z"
				fill="#FDF9F6"
			/>
		</svg>
	);
};