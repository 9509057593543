import React from 'react';

import CustomAudioPlayer from '../CustomAudioPlayer/CustomAudioPlayer';

import {GatsbyImage, getImage} from "gatsby-plugin-image";

import * as styles from './Header.module.scss';

const Header = ({ pageContext }) => {
  return (
    <header className={styles.header}>
      <div className={styles.showMobile}>
        <GatsbyImage image={getImage(pageContext.data.Cover_Photo_Mobile?.localFiles[0].childImageSharp)} alt='Cover Photo Mobile' />
      </div>
      <div className={styles.showDesktop}>
        <GatsbyImage image={getImage(pageContext.data.Cover_Photo_Desktop?.localFiles[0].childImageSharp)} alt='Cover Photo Desktop' />
      </div>
      <div className={styles.headerInfo}>
        <h1 className={styles.headerTitle}>{pageContext.data.Full_Name}</h1>
        {pageContext.data.Audio && !!pageContext.data.Audio.localFiles.length && (
          <CustomAudioPlayer audioFile={pageContext.data.Audio.localFiles[0].publicURL} />
        )}
      </div>
      <div className={styles.headerOverlay}></div>
    </header>
  );
};

export default Header;
