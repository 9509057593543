import React from 'react';
import { Link } from 'gatsby';

import SwiperCore, { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import {GatsbyImage, getImage} from "gatsby-plugin-image";

import * as styles from './CustomCarousel.module.scss';

SwiperCore.use([FreeMode]);

const CustomCarousel = ({
  feedCarousel = false,
  altText = null,
  data,
  friendsData = null,
}) => {
  const feedCarouselSettings = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.05,
        },
      },
    ],
  };

  return feedCarousel ? (
    <div className={`${styles.carouselWrapper} feed-carousel-wrapper`}>
      <Swiper
        slidesPerView={1.08}
        spaceBetween={5}
        freeMode={true}
        breakpoints={{
          // when window width is >= 700
          700: {
            slidesPerView: 2,
            allowSlideNext: false,
            allowSlidePrev: false,
          },
        }}
      >
        {data?.map((photo, index) => {
          return (
            <SwiperSlide key={`feedCarouselImage-${index}`}>
              <GatsbyImage image={getImage(photo?.childImageSharp)} alt={altText} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : (
    <div
      className={`${styles.carouselWrapper} ${styles.friendsCarouselWrapper}`}
    >
      <Swiper
        slidesPerView={2.5}
        spaceBetween={25}
        freeMode={true}
        breakpoints={{
          500: {
            slidesPerView: 3.5,
          },
          // when window width is >= 700
          700: {
            slidesPerView: 5,
            allowSlideNext: false,
            allowSlidePrev: false,
          },
        }}
      >
        {!!friendsData.length &&
          friendsData.map((friend, index) => {
            return (
              <SwiperSlide
                className="friends-carousel__single-item"
                key={`friendsCarouselImage-${friend.edges[0].node.data.Full_Name}-${index}`}
              >
                <Link to={`/${friend.edges[0].node.data.Name}`}>
                  <article className={styles.friendsCarouselElement}>
                    <GatsbyImage
                      // key not necessary
                      // key={friend.edges[0].node.data.Cover_Photo_Mobile?.raw[0].url}
                      image={getImage(friend.edges[0].node.data.Cover_Photo_Mobile?.localFiles[0]?.childImageSharp)}
                      alt={friend.edges[0].node.data.Full_Name}
                    />
                    <span className={styles.title}>
                      {friend.edges[0].node.data.Full_Name}
                    </span>
                  </article>
                </Link>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default CustomCarousel;
