import React from "react";

export const Logo = () => {
	return (
		<svg
			width="49"
			height="16"
			viewBox="0 0 49 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9383 10.4463L18.9169 10.7107C19.1139 12.4298 19.7708 13.7521 20.756 14.6777C21.807 15.6033 23.3177 16 25.2225 16C26.5362 16 27.6528 15.8017 28.5724 15.4711C29.492 15.0744 30.1488 14.5455 30.6743 13.7521C31.1341 12.9587 31.3968 12.1653 31.3968 11.2397C31.3968 10.2479 31.1997 9.45455 30.8056 8.79339C30.4115 8.13223 29.8204 7.60331 29.0979 7.20661C28.3753 6.80992 27.2587 6.41322 25.748 6.08264C24.2373 5.75207 23.252 5.35537 22.9236 5.02479C22.5952 4.76033 22.4638 4.42975 22.4638 4.09917C22.4638 3.70248 22.6609 3.3719 22.9893 3.10744C23.5147 2.71074 24.2373 2.57851 25.0912 2.57851C25.945 2.57851 26.6019 2.77686 27.0617 3.10744C27.5215 3.43802 27.7842 4.03306 27.9156 4.82645L31.0027 4.69421C30.937 3.23967 30.4115 2.1157 29.492 1.2562C28.5067 0.396694 27.0617 0 25.0912 0C23.9088 0 22.9236 0.198347 22.0697 0.528926C21.2158 0.859504 20.6247 1.38843 20.1649 2.1157C19.7051 2.77686 19.508 3.50413 19.508 4.29752C19.508 5.55372 19.9678 6.54545 20.9531 7.40496C21.6099 8 22.7922 8.52893 24.4343 8.92562C25.748 9.2562 26.5362 9.45455 26.9303 9.58678C27.4558 9.78512 27.8499 9.98347 28.0469 10.2479C28.244 10.5124 28.3753 10.843 28.3753 11.1736C28.3753 11.7686 28.1126 12.2314 27.5871 12.6942C27.0617 13.0909 26.3391 13.3554 25.3539 13.3554C24.4343 13.3554 23.6461 13.0909 23.1206 12.6281C22.5295 12.1653 22.1354 11.438 21.9383 10.4463ZM12.0858 15.7355H15.4357L9.2614 0.264463H5.97721L0 15.7355H3.28418L4.59786 12.2314L5.51743 9.65289L7.6193 3.90083L9.72118 9.65289H7.55362L5.38606 12.0331L5.18901 12.2314H10.7064L12.0858 15.7355ZM36.7172 15.7355V0.264463H39.8043V6.34711V8.99174V15.7355H36.7172ZM40.0013 8.99174H45.8472V15.7355H48.9343V0.264463H45.8472V6.34711H42.3003L41.118 7.66942L40.0013 8.99174Z"
				fill="#51433C"
			/>
			<path
				d="M17.9315 15.8012C18.3305 15.8012 18.654 15.4756 18.654 15.074C18.654 14.6723 18.3305 14.3467 17.9315 14.3467C17.5325 14.3467 17.209 14.6723 17.209 15.074C17.209 15.4756 17.5325 15.8012 17.9315 15.8012Z"
				fill="#51433C"
			/>
			<path
				d="M33.4986 15.8012C33.8977 15.8012 34.2212 15.4756 34.2212 15.074C34.2212 14.6723 33.8977 14.3467 33.4986 14.3467C33.0996 14.3467 32.7761 14.6723 32.7761 15.074C32.7761 15.4756 33.0996 15.8012 33.4986 15.8012Z"
				fill="#51433C"
			/>
		</svg>
	);
};