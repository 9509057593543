import React from "react";

export const IconTiktok = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.39817 0.0151875C10.3797 0 11.3557 0.009 12.3305 0C12.3895 1.14806 12.8024 2.3175 13.6428 3.12919C14.4815 3.96113 15.6678 4.34194 16.822 4.47075V7.49081C15.7404 7.45538 14.6536 7.23037 13.672 6.76463C13.2445 6.57113 12.8463 6.32194 12.4565 6.06713C12.4514 8.25863 12.4655 10.4473 12.4424 12.6298C12.3839 13.6783 12.038 14.7218 11.4282 15.5858C10.4472 17.0241 8.74455 17.9618 6.99573 17.991C5.92305 18.0523 4.85148 17.7598 3.93742 17.2209C2.42261 16.3277 1.35667 14.6925 1.20142 12.9375C1.18342 12.5623 1.17723 12.1877 1.19242 11.8215C1.32742 10.3944 2.03336 9.02925 3.12911 8.10056C4.37111 7.01888 6.11092 6.50362 7.73992 6.8085C7.75511 7.91944 7.71067 9.02925 7.71067 10.1402C6.96648 9.89944 6.09686 9.96694 5.44661 10.4186C4.97186 10.7263 4.6113 11.1977 4.42342 11.7309C4.26817 12.1112 4.31261 12.5336 4.32161 12.9375C4.49992 14.1683 5.68342 15.2027 6.9468 15.0907C7.78436 15.0817 8.58705 14.5957 9.02355 13.8842C9.16473 13.635 9.3228 13.3802 9.33123 13.0871C9.40492 11.7456 9.37567 10.4096 9.38467 9.06806C9.39086 6.04463 9.37567 3.02962 9.39873 0.01575L9.39817 0.0151875Z"
				fill="#51433C"
			/>
		</svg>
	);
};

