import React from 'react';
import { IconComponents } from '../../constants';
import * as styles from './LinkIcon.module.scss';

export function LinkIcon({ icon, type, text, redirect = false }) {
  const IconComponent = IconComponents[type];

  const redirectProps = !redirect && {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  if (IconComponent) {
    return (
      <li key={type}>
        <a
          href={icon}
          {...redirectProps}
        >
          <div className={styles.socialIconWrapper}>
            <IconComponent />
          </div>
          <span>{text}</span>
        </a>
      </li>
    );
  }

  return null;
}