import React from "react";

export const IconYoutube = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.1376 14.0311C6.35323 13.9749 4.65636 13.9311 2.95948 13.8655C2.59386 13.8499 2.22198 13.7936 1.86573 13.7092C1.19698 13.553 0.734481 13.1499 0.496981 12.4905C0.306356 11.9592 0.237606 11.4124 0.187606 10.8561C0.0782308 9.54987 0.0782308 8.24362 0.131356 6.93737C0.162606 6.19987 0.181356 5.45612 0.378231 4.7405C0.496981 4.31237 0.640731 3.89362 0.987606 3.58425C1.35323 3.25612 1.78136 3.05925 2.26886 3.03737C3.60636 2.97175 4.94073 2.8905 6.27823 2.878C8.07823 2.85925 9.88135 2.88425 11.6814 2.91862C12.4595 2.93425 13.2439 2.97487 14.0157 3.07487C14.722 3.16862 15.2501 3.56237 15.5314 4.24987C15.747 4.778 15.8282 5.33112 15.8751 5.89362C15.997 7.303 16.0001 8.71237 15.9314 10.1217C15.897 10.8374 15.8626 11.5561 15.6595 12.253C15.4282 13.053 14.9376 13.5811 14.1189 13.7624C13.8001 13.8311 13.4689 13.8561 13.1439 13.8686C11.4439 13.928 9.74698 13.9811 8.1376 14.0311ZM6.40948 10.478C7.83448 9.7405 9.24385 9.00925 10.6814 8.26237C9.24698 7.51237 7.8376 6.778 6.40948 6.03112C6.40948 7.52175 6.40948 8.98737 6.40948 10.478Z"
				fill="#51433C"
			/>
		</svg>
	);
};