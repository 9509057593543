import React from 'react';
// import { Helmet } from 'react-helmet';
// import { withPrefix } from 'gatsby';

const FormMailingList = ({ mailingListCode }) => {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = withPrefix('mailerlite.js');
  //   document.head.appendChild(script);

  //   return () => {
  //     console.log('from SectionMailingList unmount');
  //     const scripts = document.querySelectorAll('script');
  //     console.log(scripts);
  //     if (!!scripts.length) {
  //       scripts.forEach((singleScript) => {
  //         console.log(
  //           singleScript.src.includes(
  //             'https://static.mailerlite.com/data/a/3591/3591491/'
  //           )
  //         );
  //         if (
  //           singleScript.src.includes(
  //             'https://static.mailerlite.com/data/a/3591/3591491/'
  //           )
  //         ) {
  //           console.log(document.body);
  //           document.body.removeChild(singleScript);
  //         }
  //       });
  //     }
  //     document.head.removeChild(script);
  //   };
  // }, []);

  return (
    <div
      className="ml-form-embed"
      data-account="3591491:t6b6z7d7s0"
      data-form={mailingListCode}
    ></div>
  );
};

export default FormMailingList;
