import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import CircleControls from 'react-player-circle-controls';
import 'react-player-circle-controls/dist/styles.css';

import * as SvgSprite from '../../assets/SvgSprite';

const CustomAudioPlayer = ({ audioFile }) => {
  const player = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [playerState, setPlayerState] = useState({
    played: 0,
    loaded: 0,
  });

  const handleOnBlur = () => {
    setPlaying(false);
  };

  useEffect(() => {
    window.addEventListener('blur', handleOnBlur);

    return () => {
      window.removeEventListener('blur', handleOnBlur);
    };
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  const onSeek = (amount) => {
    if (player.current) {
      player.current.seekTo(amount, 'fraction');
    }
  };

  return (
    <>
      <ReactPlayer
        ref={player}
        url={audioFile}
        playing={playing}
        height="0"
        width="0"
        onProgress={setPlayerState}
        onEnded={() => {
          // setCurrentAudioState('idle');
          setPlaying(false);
        }}
      />
      <CircleControls
        played={playerState.played}
        loaded={playerState.loaded}
        playing={playing}
        onSeek={onSeek}
        onTogglePlaying={() => {
          if (!playing) {
            window.dataLayer.push({ event: 'audio-play' });
          }

          if (playing) {
            window.dataLayer.push({ event: 'audio-pause' });
          }

          setPlaying(!playing);
        }}
        icon={playing ? <SvgSprite.IconPause /> : <SvgSprite.IconPlay />}
      />
    </>
  );
};

export default CustomAudioPlayer;
