import React from 'react';
import * as styles from './SectionMoreInfo.module.scss';
import { DEFAULT_ICON_ORDER } from '../../constants';
import { LinkIcon } from '../LinkIcon/LinkIcon';

function orderIcons(pageContextData, customIconsDataRaw) {
  // create array for default icons
  const defaultIcons = DEFAULT_ICON_ORDER.reduce((acc, currIcon) => {
    const link = pageContextData[currIcon.icon];
    if (link) {
      const orderKey = currIcon.icon + '_Order';
      // if order is not provided, set it to a large number
      const order = pageContextData[orderKey] != null ? pageContextData[orderKey] : 10000;
      const redirect = currIcon.icon === 'Exclusive_Content';
      acc.push({
        icon: redirect ? `/${pageContextData.Name}/out` : link,
        type: currIcon.icon,
        order: order,
        text: currIcon.text,
        redirect: redirect,
      });
    }
    return acc;
  }, []);

  // create array for custom icons
  const customIcons = customIconsDataRaw.reduce((acc, currIcon) => {
    const customIcon = currIcon?.node?.data;
    if (customIcon) {
      // if order is not provided, set it to a large number
      const order = customIcon?.Order ?? 10000;
      acc.push({
        icon: customIcon.Custom_Link_URL,
        text: customIcon.Custom_Link_Text,
        order: order,
        type: 'Star',
        redirect: false,
      });
    }
    return acc;
  }, []);

  // combine the two arrays
  const finalIcons = [...defaultIcons, ...customIcons];

  // sort the final array by order
  finalIcons.sort((a, b) => a.order - b.order);

  return finalIcons;
}

const SectionMoreInfo = ({ pageContext }) => {
  const icons = orderIcons(pageContext.data, pageContext?.customLinks?.edges);
  return (
    <section className={styles.sectionMoreInfo}>
      <h4>More info</h4>
      <ul className={styles.socialMedia}>
        {icons.map((iconData) => {
          return <LinkIcon {...iconData} key={iconData.icon} />;
        })}
      </ul>
    </section>
  );
};

export default SectionMoreInfo;
