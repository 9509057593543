import React from "react";

export const IconOnlyfans = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1009_1763)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.974 0C5.35065 0 0 5.37662 0 12.026C0 18.6753 5.37662 24.026 12.026 24C18.6494 24 24 18.6234 24 12C24 5.37662 18.6234 0 11.974 0C12 0 12 0 11.974 0ZM11.974 22.7532C6.02597 22.7273 1.22078 17.8961 1.24675 11.974C1.27273 6.05195 6.07792 1.24675 12.026 1.24675C17.9481 1.27273 22.7532 6.07792 22.7532 12C22.7532 17.9481 17.9481 22.7532 12 22.7532H11.974Z"
					fill="white"
				/>
				<path
					d="M16.8831 11.0129V10.0259C16.8831 8.8571 16.4155 7.74022 15.5584 6.90905C14.7272 6.07788 13.5844 5.61035 12.3896 5.61035H11.5844C10.3896 5.61035 9.27268 6.07788 8.41554 6.90905C7.58437 7.71425 7.09086 8.8571 7.09086 10.0259V11.0129L6.54541 12V13.4545C6.54541 14.7532 7.06489 16 7.99996 16.9091C8.93502 17.8441 10.2077 18.3376 11.5324 18.3376H12.4415C13.7662 18.3376 15.0129 17.8181 15.948 16.9091C16.8831 16 17.4026 14.7532 17.4026 13.4545V12L16.8831 11.0129ZM12.5194 15.4545V16.6233C12.5194 16.8052 12.4415 16.961 12.2857 17.0389H11.7662C11.6103 16.935 11.5324 16.7792 11.5324 16.5974V15.4285C10.8311 15.1428 10.4675 14.3636 10.7532 13.6363C10.961 13.1168 11.4805 12.7532 12.0259 12.7532H12.1039C12.8571 12.7532 13.4805 13.3506 13.4805 14.1298C13.4805 14.7013 13.1428 15.2207 12.5974 15.4285H12.5194V15.4545ZM14.8831 11.0649H9.11684V10.0519C9.11684 9.40256 9.37658 8.77918 9.84411 8.31165C10.3116 7.84412 10.935 7.58438 11.5844 7.58438H12.3896C13.0389 7.58438 13.6623 7.84412 14.1298 8.31165C14.5974 8.75321 14.8571 9.37658 14.8571 10.0259L14.8831 11.0649Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1009_1763">
					<rect width="24" height="24" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};