import React from 'react';
import { Helmet } from 'react-helmet';

const isDev = process.env.NODE_ENV === "development"
const siteUrl = isDev ? "http://localhost:8000" : "https://ash.fans"

function SEO({ fullName, gtm, metaURL, title, description, OGImage }) {
  // const shouldInsertGTM =
  //   process.env.NODE_ENV === 'production' &&
  //   !!process.env.GATSBY_DEPLOY_ENVIRONMENT &&
  //   process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production' &&
  //   !!gtm;

  const shouldInsertGTM = process.env.NODE_ENV === 'production' && !!gtm;

  return shouldInsertGTM ? (
    <Helmet>
      <title>
          {title ? title : `Everything about ${fullName}`}
      </title>

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title ? title : `Everything about ${fullName}`} />
      {OGImage && <meta property="og:image" content={`${siteUrl}${OGImage}`} />}
      {description && <meta property="og:description" content={description}/>}
      <meta
        property="og:url"
        content={metaURL}
      />
      <meta property="og:site_name" content={`Everything about ${fullName}`} />
      <meta
        property="article:modified_time"
        content="2021-10-04T23:55:57+00:00"
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="icon" href="../../../static/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="../../../apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../../favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../../favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../../favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../../favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="../../../android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="../../../android-chrome-512x512.png"
      />

      <script type="text/javascript">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtm}');`}</script>

      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
    </Helmet>
  ) : (
    <Helmet>
      <title>
        {title ? title : `Everything about ${fullName}`}
      </title>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title ? title : `Everything about ${fullName}`} />
    {OGImage && <meta property="og:image" content={`${siteUrl}${OGImage}`} />}
      {description && <meta property="og:description" content={description}/>}
      <meta
        property="og:url"
        content={metaURL}
      />
      <meta property="og:site_name" content={`Everything about ${fullName}`} />
      <meta
        property="article:modified_time"
        content="2021-10-04T23:55:57+00:00"
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="icon" href="../../../static/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="../../../apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../../favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../../favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../../favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../../favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="../../../android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="../../../android-chrome-512x512.png"
      />
    </Helmet>
  );
}
export default SEO;
