import React, { useState, useEffect } from 'react';

import CustomCarousel from '../CustomCarousel/CustomCarousel';

import * as styles from './SectionFriends.module.scss';

const Friends = ({ pageContext }) => {
  const [randomFriends, setRandomFriends] = useState([]);

  const friends = pageContext.friends;

  const getRandomOrder = (arr) => {
    const clone = arr.slice();
    const ret = [];
    while (clone.length > 0) {
      const obj = clone.splice(Math.floor(Math.random() * clone.length), 1)[0];
      ret.push(Object.assign({}, obj));
    }
    return ret.slice(0, 8);
  };

  useEffect(() => {
    const tmp = getRandomOrder(friends);

    setRandomFriends(tmp);
  }, []);

  return !!randomFriends.length ? (
    <section className={styles.sectionFriends}>
      <h4 className={styles.sectionTitle}>My friends</h4>
      <CustomCarousel friendsData={randomFriends} />
    </section>
  ) : null;
};

export default Friends;
